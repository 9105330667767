@import url('@fortawesome/fontawesome-free/css/all.min.css');

.pulse-ring {
	content: '';
	background: #282828;
	/* border: 2px solid #282828; */
	border-radius: 50%;
	animation: googleMicPulse 1.5s infinite;
  /* background: transparent; */
}

.pulse-ring.delay {
	animation-delay: 0.25s;
}

@keyframes googleMicPulse {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	25% {
		transform: scale(1.4);
		opacity: 0.8;
	}
	50% {
		transform: scale(1.1);
		opacity: 0.9;
	}
	75% {
		transform: scale(1.3);
		opacity: 0.7;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.chat-area::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .chat-area {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }