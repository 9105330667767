:root {
  /* Accept button color */
  --button-color: #0077ff;

  /* Cancel button color */
  --button-color-alt: red;

  /* Font sizes */
  --font-size-base: 14px;
  --font-size-md: 15px;
  --font-size-lg: 17px;
  --font-size-xl: 18px;
  --font-size-xxl: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-65%);
  z-index: 1000;
}

.modal-content {
  background-color: whitesmoke;
  margin: 15%;
  padding: 15px;
  border: 1px solid black;
  width: 100%;
  min-width: 220px;
  max-width: 400px;
  border-radius: 6px;
}

.modal-content p {
  font-weight: 500;
  font-size: var(--font-size-base);
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  align-items: center;
}

.modal-button,
.modal-button-alt {
  font-family: inherit;
  width: 4.5em;
  height: 2em;
  overflow: hidden;
  cursor: pointer;
  font-size: 17px;
  z-index: 1;
  border-radius: 6px;
  position: relative;
}

.modal-button {
  color: var(--button-color);
  border: 2px solid var(--button-color);
}

.modal-button-alt {
  color: var(--button-color-alt);
  border: 2px solid var(--button-color-alt);
}

@media (min-width: 576px) {
  .button-container {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .modal-button,
  .modal-button-alt {
    font-size: var(--font-size-md);
    width: 6em;
    height: 2.4em;
  }
  .modal-content {
    padding: 20px;
  }
  .modal-content p {
    font-weight: 500;
    font-size: var(--font-size-md);
  }
  .button-container {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .modal-button,
  .modal-button-alt,
  .modal-content p {
    font-size: var(--font-size-lg);
  }
}

@media (min-width: 992px) {
  .modal-button,
  .modal-button-alt {
    font-size: var(--font-size-lg);
  }
  .modal-content p {
    font-size: var(--font-size-xl);
  }
  .button-container {
    margin-top: 20px;
  }
}
